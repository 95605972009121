import { Trainer } from "../models/trainer";
import TrainerList from "../TrainerList";

const TrainersContent = () => {
    const trainers: Trainer[] = [
        {
            key: 'Evan',
            nameSurname: 'Evan BOISSONNOT',
            description: `Informations personnelles
            Passionné de code depuis l'âge de 9 ans (jour où j'ai eu mon Amstrad 6128 plus), je n'ai plus arrêter de coder, programmer depuis.
            
            Depuis tout petit, j'aime aussi apprendre, enseigner, aider les autres à acquérir de nouvelles connaissances.
            
            En joignant ces deux passions, j'avais trouvé ma voix : devenir formateur ! 
            
            Au plaisir de vous proposer mes cours, de vous apporter un enseignement ludique et fun. 
            
            
            
            - Techniquement parlant
            
            J'ai commencé à apprendre à programmer avec le langage BASIC.
            
            Puis, j'ai eu une période Windev, PHP, et VBA, avant de trouver mon premier langage d'affection : C# et le framework dotnet.
            Je suis devenu développeur .net depuis 2002, lors de la création du framework par Microsoft.
            
            J'ai tellement cru en ce langage et à son framework, que j'ai créé une entreprise dédiée (au démarrage) à deux de mes passions : .net et l'agilité.
            NETil était née, et elle a duré 8 ans.
            
            Durant ces 8 ans, nous avons (nous étions 14) développé principalement en .net, en node.js et en angular, tout ça avec git
            
            C'est à ce moment là que j'ai commencé à donner des formations dotnet, nodejs et agile.
            
            
            
            - Et aujourd'hui
            
            Je partage mon temps entre trois activités :
            
            -> Formateur, of course :D
            
            -> Senior développeur, CTO externalisé
            
            -> Développeur d'un jeu video : Synale memories
            
            
            
            Pour me suivre sur github : evan-boissonnot.
            
            
            
            - Mes passions
            
            Le code, la programmation
            
            La formation, apprendre à apprendre.
            
            Star wars, marvel, ...
            La SF !
            
            Le blogging
            
            Le badminton
            
            Le footing
            
            Mes enfants
            
            
            
            Mes formations ? 
            
            Aujourd'hui, je donne des formations présentielles / distancielles, gamifiées, autour de :
            
            - Git : les bases, le niveau 2, github, gitlab, ...
            
            - C# : apprendre le langage, découvrir les dernière nouveautés, monter au niveau intermédiaire, expert
            
            - MVC net : créer un site mvc net, optimiser ses sites internet mvc net
            
            - asp net : la même que pour mvc net
            
            - architecture logicielle : bien coder, green coding, les bases de l'architecture, les design patterns
            
            - WPF / UWP : créer des applications riches avec du XAML
            
            - Angular : débuter en angular 6 et 7
            
            - Nodejs : créer un site internet nodejs
            
            - agilité : découvrir l'approche agile, apprendre SCRUM, maitriser SCRUM
            
            
            
            Je crée en continue des  formations en lignes.
            
            
            
            Et enfin, je suis en train de monter une startup, yeah
            
            
            
            A très vite autour d'une formation  :)
            
            Evan`
        }
    ];

    const style: React.CSSProperties = {textAlign: 'center'};

    return (
        <>
            <h2 style={style} >Tes formateurs</h2>
            <TrainerList items={trainers}></TrainerList>
        </>
    );
};

export default TrainersContent;