import { FunctionComponent } from "react";
import { CommercialLanguage } from "../models/commercial-langage";

type defaultProp = {
    item: CommercialLanguage
};

const CommercialLanguageItem: FunctionComponent<defaultProp> = (props) => {
    const style: React.CSSProperties = {
        backgroundImage: `url(/icones/languages/${props.item.url}.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '7em',
        height: '100px',
        width: '100px',
        flexDirection: 'row',
        margin: 'auto'
    };

    return (
        <>
            <li >
                <div style={style}></div>
            </li>
        </>
    );
};

export default CommercialLanguageItem;
