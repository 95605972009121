import { NavLink } from "react-router-dom";
import styles from './MenuSection.module.css';

const MenuSection = () => {
    return (
        <>
            <div className={styles.menu}>
                <div className={styles.menuItem}>
                    <NavLink autoCapitalize="true" to="/tes-formations-excellence-developpement">Tes formations</NavLink>
                </div>
                <div className={styles.menuItem}>
                    <NavLink autoCapitalize="true" to="/ta-communaute">Ta communauté</NavLink>
                </div>
            </div>
        </>
    );
};

export default MenuSection;