import { Card, CardContent, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';


type propCard = {
    border?: boolean;
    boxShadow?: any;
    children?: React.ReactNode;
    content?: string;
    contentClass?: string;
    contentSX?: any;
    darkTitle?: any;
    secondary?: any;
    shadow?: any;
    sx?: {};
    title?: string;
};

const MainCard = React.forwardRef<HTMLInputElement, propCard>((props, ref) => {
    const theme: SxProps<Theme> = {
        marginX: '10px',
        marginTop: '10px',
        boxShadow: 2
    };

    return (
        <Card variant="outlined" sx={theme}>
            <React.Fragment>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {props.title}
                    </Typography>
                    <Typography variant="body2">{props.content}</Typography>
                </CardContent>
            </React.Fragment>
        </Card>
    );
});

export default MainCard;
