import { Card, CardContent, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import MainCard from '../../../shared/components/ui/MainCard';

type defaultProp = {
    title: string;
    content: string;
};

const TestimonialResume: FunctionComponent<defaultProp> = (props) => {    

    return (
        <>
            <MainCard title={props.title} content={props.content} sx={{marginTop: '10px'}}>
            </MainCard>
        </>
    );
};

export default TestimonialResume;
