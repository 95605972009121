import axios from 'axios';
import apis from '../../../config/apis';
import { Testimonial } from '../models/testimonial';

export class TestimonialService {
    async getAll() {
        const result = await axios.get<Testimonial[]>(apis.testimonials.url);
        return result.data;
    }
}

const testimonialService = new TestimonialService();
export default testimonialService;