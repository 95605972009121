import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Trainer } from "../models/trainer";

type defaultProp = {
    item: Trainer
};

const TrainerItem: FunctionComponent<defaultProp> = (props) => {
    return (
        <>
            <Card sx={{ minWidth: 275, boxShadow: '0 2px 8px grey' }}>
                <CardContent>
                <Typography sx={{ fontSize: 25 }} color="text.secondary" gutterBottom>
                    {props.item.nameSurname}
                </Typography>
                <Typography sx={{ fontSize: 18, lineHeight: '2em' }}>
                    {props.item.description}
                </Typography>
                </CardContent>
            </Card>
        </>
    );
};

export default TrainerItem;