import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SearchSection from '../../../shared/components/ui/SearchSection';
import { useAppSelector } from '../../../store/hooks';
import CoursesList from '../CoursesList';
import { fetchCourses } from '../store/courseReducer';
import { selectFilterCourses } from '../store/courseSelectors';

const CoursesContent = () => {
    const state = useAppSelector((state) => state.courses);
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(fetchCourses());
    }, [dispatch]);

    const style = {
        margin: 'auto',
        maxWidth: '60rem'
    };

    const filterCourses = (value: string) => {
        setSearchValue(value);
    }

    return (
        <>
            <Grid style={style}>
                <h1>Vos formations</h1>
                <h2>Des aventures de haute qualité, gamifiées, de formation</h2>
                <SearchSection width='100%' newValue={filterCourses} />
                {state.loading === 'pending' && <i>Loading ...</i>}
                <CoursesList items={selectFilterCourses(state, searchValue)}></CoursesList>
            </Grid>
        </>
    );
};

export default CoursesContent;
