import { SxProps, Theme } from "@mui/material";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ColorButton } from "../StyledButton";

type defaultProp = {
    url: string,
    text: string;
    style?: {
        backgroundColor: string;
        color: string
    }
};

const GoToAction: FunctionComponent<defaultProp> = (props) => {
    let navigate = useNavigate();

    const goToUrl = () => {
        if (props.url.indexOf('http') >= 0) {
            window.open(props.url, '_blank');
        } else  {
            navigate(props.url);
        }
    }

    let sx: SxProps<Theme> = {};

    if (props.style) {
        sx = props.style;
    }

    return (
        <>
            <div>
                <ColorButton sx={sx} size="large" onClick={goToUrl} variant="outlined">{props.text}</ColorButton>
            </div>
        </>
    );
};

export default GoToAction;