import { styled, useTheme } from '@mui/material/styles';
import { drawerWidth } from '../../store/constants';
import { Theme } from '@mui/system';

type themeProps = {
    theme: Theme,
    open: any
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })((props: themeProps) => ({

    //...theme.typography.mainContent,
    ...(!props.open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: 80,
        marginLeft: 5,
        marginRight:5
    }),
    ...(props.open && {
        padding: 60,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`
    })
}));

export default Main;