import TestimonialResume from '../TestimonialResume';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { FunctionComponent } from 'react';
import { Testimonial } from '../models/testimonial';

type defaultProp = {
    items: Testimonial[]
};

const TestimonialsList: FunctionComponent<defaultProp> = (props) => {
    const resumes = props.items.map((item) => <TestimonialResume key={item.id} title={item.name} content={item.comments}></TestimonialResume>);

    const theme: SxProps<Theme> = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    };

    return (
        <>
            <Box
                sx={theme}
            >
                {resumes}
            </Box>
        </>
    );
};

export default TestimonialsList;
