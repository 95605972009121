import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FunctionComponent } from 'react';
import LogoSection from './LogoSection';
import MenuSection from './MenuSection';

type props = {
    handleLeftDrawerToggle: () => void;
};

const Header: FunctionComponent<props> = (props) => {
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>
            
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }}>
                <MenuSection></MenuSection>
            </Box>
        </>
    );
};

export default Header;
