import Box, { BoxProps } from '@mui/material/Box';

const Item = (props: BoxProps) => {
    const { sx, ...other } = props;

    return (
        <Box
            sx={{
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
                p: 1,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx
            }}
            {...other}
        />
    );
};

export default Item;