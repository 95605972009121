import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import CommercialContent from '../features/commercial/CommercialContent';
import CommercialPresentation from '../features/commercial/CommercialPresentation';
import MainPicture from '../features/home/MainPicture';
import TestimonialsContainer from '../features/testimonials/TestimonialsContainer';
import TrainersContent from '../features/trainers/TrainersContent';
import Item from '../shared/components/ui/ItemGrid';

const DashboardDefault = () => {
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        document.title = 'Curious academy';
        setLoading(false);
    }, []);

    return (
        <>
            <Grid
                paddingTop={'20px'}
                container
            >
                <Grid item xs={12} md={5}>
                    <CommercialContent></CommercialContent>
                </Grid>
                <Grid item xs={12} md={7}>
                    <MainPicture></MainPicture>
                </Grid>
            </Grid>

            <Grid
                paddingTop={'80px'}
                item xs={12}
            >
                <CommercialPresentation></CommercialPresentation>
            </Grid>

            <Grid
                paddingTop={'20px'}
                sx={{
                    display: 'grid',
                    gap: 1,
                    gridTemplateColumns: '1fr'
                }}
            >
                <Item sx={{ gridColumn: '1' }}>
                    <Typography sx={{ textAlign: 'center' }} variant="h2" color="text.secondary" gutterBottom>
                        Les formé-e-s donnent leur avis !
                    </Typography>
                </Item>
                <Item sx={{ gridColumn: '1' }}>
                    <TestimonialsContainer></TestimonialsContainer>
                </Item>
            </Grid>
            <Grid
                paddingTop={'20px'}
                sx={{
                    display: 'grid',
                    gap: 1,
                    gridTemplateColumns: '1fr'
                }}
            >
                <Item sx={{ gridColumn: '1' }}>
                    <TrainersContent></TrainersContent>
                </Item>
            </Grid>
        </>
    );
};

export default DashboardDefault;
