import { Grid } from "@mui/material";
import { FunctionComponent } from "react";
import logo from '../../../assets/images/devtobecurious.png';
import CommercialLanguages from "../CommercialLanguages";
import styles from './CommercialPresentation.module.css';

const CommercialPresentation = () => {
    return (
        <>
            <Grid  alignItems="center">
                <header className={styles.header}>
                    <div className={styles.headerImage}>
                        <img alt='Dev to be curious' src={logo}></img>
                    </div>
                    <div>
                        <h2>
                            Curious academy, c'est plus de 10 aventures de formation, gamifiées.<br></br>
                            Avec des vidéos de haute qualité, rejoins l'aventure Curious academy et excèle 
                            sur les derniers langages choisis par les entreprises !
                        </h2>
                    </div>
                </header>
                <CommercialLanguages></CommercialLanguages>
            </Grid>
        </>
    );
};

export default CommercialPresentation;