import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../../shared/components/ui/Logo';
import config from '../../../config';

const LogoSection = () => (
    <>
        <ButtonBase sx={{justifyContent: "left" }} disableRipple component={Link} to={config.defaultPath}>
            <Logo />
        </ButtonBase>
    </>
);

export default LogoSection;
