import { createSelector } from '@reduxjs/toolkit';
import { Course } from '../models/course';
import {CoursesState} from './courseReducer';

const selectCourses = (state: CoursesState) => state.entities;

const filterValue = (state: CoursesState, filterValue: string) => filterValue;

const coursesFilteringByValue = (courses: Course[], filter: string) =>  {
    return  courses.filter(item => item.title.toLowerCase().includes(filter) || 
                                   item.description.toLowerCase().includes(filter));
};

export const selectFilterCourses = createSelector([selectCourses, filterValue], coursesFilteringByValue);
