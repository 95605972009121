import CommercialItem from "../CommercialItem";
import { CommercialPresentationItem } from "../models/commercial-content";
import React, { FunctionComponent } from 'react';

type defaultProp = {
    items: CommercialPresentationItem[]
}

const CommercialDescription: FunctionComponent<defaultProp> = (props) => {
    const lis = props.items.map(item => <CommercialItem key={item.id} text={item.content} imageUrl={item.url}></CommercialItem>);

    return (
        <>
            <ul>
                {lis}
            </ul>
        </>
    );
};

export default CommercialDescription;