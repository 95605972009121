import axios from 'axios';
import apis from '../../../config/apis';
import { Course } from '../models/course';

export class CourseService {
    async getAll() {
        const result = await axios.get<Course[]>(apis.courses.url);
        return result.data;
    }
}

const courseService = new CourseService();
export default courseService;