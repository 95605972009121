import { useTheme } from '@mui/material/styles';
import logoPicture from '../../../../assets/images/logo.png';
import styles from './Logo.module.scss';

const Logo = () => {
    const theme = useTheme();

    return (
        <>
            <img className={styles.logo} alt="logo" src={logoPicture}></img>
        </>
    );
};

export default Logo;