import { FunctionComponent } from "react";
import CommercialLanguageItem from "../CommercialLanguageItem";
import { CommercialLanguage } from "../models/commercial-langage";
import styles from "./CommercialLanguageList.module.css";

type defaultProp = {
    items: CommercialLanguage[]
};

const CommercialLanguageList: FunctionComponent<defaultProp> = (props) => {
    const items = props.items.map(language => <CommercialLanguageItem key={language.name} item={language}></CommercialLanguageItem>);

    return (
        <>
            <ul className={styles.languageList}>
                {items}
            </ul>
        </>
    );
};

export default CommercialLanguageList;
