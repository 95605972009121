import mainPng from '../../../assets/images/code-example.png';
import styles from './MainPicture.module.css';

const MainPicture = () => {
    return (
        <>
            <img src={mainPng} className={styles.mainPicture} alt="Code react"></img>
        </>
    );
};

export default MainPicture;