import CommercialLanguageList from "../CommercialLanguagesList";
import { CommercialLanguage } from "../models/commercial-langage";

const CommercialLanguages = () => {
    const languages: CommercialLanguage[] = [
        { name: 'angular', url: 'angular', status: 'now'},
        { name: 'react', url: 'react', status: 'now'},
        { name: 'vuejs', url: 'vuejs', status: 'soon'},
        { name: 'js', url: 'js', status: 'soon'},
        { name: 'typescript', url: 'typescript', status: 'soon'},
        { name: 'c#', url: 'csharp', status: 'now'},
        { name: 'asp.net mvc', url: 'mvcnet', status: 'now'},
        { name: 'asp.net webapi', url: 'webapi', status: 'now'},
        { name: 'asp.net razor', url: 'razor', status: 'soon'},
        { name: 'Blazor', url: 'blazor', status: 'soon'},
        { name: 'git', url: 'git', status: 'now'},
        { name: 'gitlab', url: 'gitlab', status: 'now'},
    ];

    return (
        <>
            <CommercialLanguageList items={languages}></CommercialLanguageList>
        </>
    );
};

export default CommercialLanguages;