import { FunctionComponent } from "react";
import { Course } from "../models/course";
import styles from './CourseResume.module.css';

type defaultProp = {
    item: Course
};

const getDurationAsString = (item: Course) => {
    let finalDuration = '> 5';

    if (typeof(item.duration) !== 'undefined') {
        finalDuration = item.duration.toString();
    }

    return `${finalDuration} hrs`;
}

const CourseResume: FunctionComponent<defaultProp> = (props) => {
    const goToWebSite = () => {
        window.open(props.item.url, '_blank');
    };

    const style: React.CSSProperties = {
        backgroundImage: `url(/courses/${props.item.key}.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '19.3em',
        height: '170px',
        width: '275px',
        flexDirection: 'row',
        margin: 'auto'
    };

    const alignStyle: React.CSSProperties = {
        textAlign: 'center'
    };

    return (
        <>
            <article className={styles.courseResume} onClick={goToWebSite} >
                <div style={style}></div>
                <h2 style={alignStyle}>{props.item.title}</h2>
                <div style={alignStyle} className="duration">
                    <i>{ getDurationAsString(props.item) }</i>
                </div>
                <div style={alignStyle} className="description">
                    {props.item.description}
                </div>
            </article>
        </>
    );
};


export default CourseResume;
