import { FunctionComponent } from "react";
import { Trainer } from "../models/trainer";
import TrainerItem from "../TrainerItem";
import styles from './TrainerList.module.css';

type defaultProp = {
    items: Trainer[]
};

const TrainerList: FunctionComponent<defaultProp> = (props) => {
    const items = props.items.map(trainer => <TrainerItem key={trainer.key} item={trainer}></TrainerItem>)

    return (
        <>
            <div className={styles.trainers}>
                {items}
            </div>
        </>
    );
};

export default TrainerList;