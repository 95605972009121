import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../shared/store/states/base-state";
import { Testimonial } from "../models/testimonial";
import testimonialService from "../services/testimonial.service";

export interface TestimonialsState extends BaseState<Testimonial> {
}

export const initialState: TestimonialsState = {
    entities: [],
    loading: 'idle'
};

export const fetchTestimonials = createAsyncThunk(
    'testimonials/getAll',
    async (_, { rejectWithValue }) => {
        try {
            return await testimonialService.getAll();
        } catch (error) {
            return rejectWithValue([]);
        }
    }
);

const testimonialSlices = createSlice({
    name: 'testimonials',
    initialState: initialState, 
    reducers: {        
    },
    extraReducers:  (builder) => {
        builder.addCase(fetchTestimonials.pending, (state, action) => {
            state.loading = "pending";
        });

        builder.addCase(fetchTestimonials.rejected, (state, action) => {
            state.loading = "failed";
            state.errorMessage = action.error.message ||'';
        });

        builder.addCase(fetchTestimonials.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.entities = action.payload;
        });
    }
});

export default testimonialSlices.reducer;