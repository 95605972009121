import { Grid } from "@mui/material";
import { FunctionComponent } from "react";
import { animated, useTransition } from "react-spring";
import CourseResume from "../CourseResume";
import { Course } from "../models/course";

type defaultProp = {
    items: Course[]
};

const CoursesList: FunctionComponent<defaultProp> = (props) => {
    console.info('list', props.items);

    const transition = useTransition(props.items, {
        key: (item: any) => item.name,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
      });

    const fadeInListItems = transition((style, item) => {
        return <animated.div key={item.key} style={style}><CourseResume item={item}></CourseResume></animated.div>;
    });    

    return (
        <>
            <Grid container>
                {fadeInListItems}
             </Grid>
        </>
    );
};


export default CoursesList;
