import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchTestimonials } from "../store/testimonialReducer";
import TestimonialsList from "../TestimonialList";

const TestimonialsContainer = () => {  
    const state = useAppSelector(state => state.testimonials);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchTestimonials());
    }, [
        dispatch
    ]);

    return (
        <>
            { state.loading === "pending" && <i>Loading ...</i>}
            <TestimonialsList items={state.entities}></TestimonialsList>
        </>
    );
};

export default TestimonialsContainer;