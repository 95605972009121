import { FunctionComponent } from "react";
import classes from './CommercialItem.module.css';

type defaultProp = {
    imageUrl: string,
    text: string
};

const CommercialItem: FunctionComponent<defaultProp> = (props) => {
    const style: React.CSSProperties = {
        backgroundImage: `url(/icones/${props.imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '3.3em',
        height: '50px',
        width: '50px',
        flexDirection: 'row'
    };

    return (
        <>
            <li className={ classes.commercialItem }>
                <div className="image" style={style}></div>
                <div className="description">
                    {props.text}
                </div>
            </li>
        </>
    )
};

export default CommercialItem;