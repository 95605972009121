import GoToAction from "../../../shared/components/ui/GoToAction";
import CommercialDescription from "../CommercialDescription";
import { CommercialPresentationItem } from "../models/commercial-content";

const CommercialContent = () => {
    const items: CommercialPresentationItem[] = [
        { id: 1, content: 'Des formations gamifiées', url: 'game-control.png' },
        { id: 2, content: 'Déjà plus de 3000 curieux-ses !', url: 'people.png' },
        { id: 3, content: 'Des avis très positifs (420 à 5/5)', url: 'pensee-positive.png' },
    ];

    return (
        <>
            <h1>Des formations pour toi <br></br> et tous-toutes les curieux-ses du code !</h1>
            <h2>Créé par Evan BOISSONNOT</h2>
            <CommercialDescription items={items}></CommercialDescription>
            <GoToAction text="Curieux-se d'en savoir plus ?" url="/tes-formations-excellence-developpement"></GoToAction>
        </>
    );
};

export default CommercialContent;